

export function getHost() {
  var protocol = window.location.protocol;
  var slashes = protocol.concat("//");
  var host = slashes.concat(window.location.hostname);
  if (protocol === "http:" && window.location.port !== 80) {
    host = host.concat(":" + window.location.port);
  } else if (protocol === "https:" && window.location.port !== 443) {
    host = host.concat(":" + window.location.port);
  }

  return host;
}

