import React from 'react';
import HomeStartBtn from './homeStartBtn.js';
import DocumentMeta from 'react-document-meta';

import './HomePage.css'
import './Branding.css'

class HomePage extends React.Component {

  render() {
    const meta = {
      title: 'virtualpastries.com - Share virtual pastries, pies and cakes with your colleagues',
      description: 'Got something to celebrate but can\'t make it into the office? Share virtual pastries, cakes, pies and donuts for free using virtualpastries.com.',
      canonical: 'https://www.virtualpastries.com',
      meta: {
          charset: 'utf-8',
          name: {
              keywords: 'virtual,digital,pastry,pie,cake,celebration,colleagues,birthday'
          }
      }
    };

    return (
      <>
        <DocumentMeta {...meta} />
        <div className="background">
          <div className="jumbotron">
            <p>
              <span className="brand brand-virtual">virtual</span><span className="brand brand-pastries">pastries</span><span className="brand brand-com">.com</span>
            </p>
            <p>
              Got something to celebrate but can't make it into the office?
            </p>
            <p>
              Share virtual pastries, cakes, pies and donuts using our <b>free</b> service!
            </p>
            <div id="spacer1"></div>
            <HomeStartBtn></HomeStartBtn>
          </div>
        </div>
      </>
  )}
}

export default HomePage;