import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams
} from "react-router-dom";
import './App.css';

//import {ThemeProvider} from '@material-ui/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import CelebrationPage from './CelebrationPage';

import CreationPage from './CreationPage.js';

import HomePage from './HomePage.js';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Abel',
  }
});


function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Switch>
            <Route path="/celebration/:id">
              <Celebration />
            </Route>
            <Route path="/create/">
              <Creation />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </ThemeProvider>
    </Router>
  );
}
function Home() {
  return <HomePage></HomePage>;
}

function Celebration() {
  let { id } = useParams();
  // TODO: This could be neater.
  return <CelebrationPage id={id}></CelebrationPage>;
}
function Creation() {
  // TODO: This could be neater.
  return <CreationPage ></CreationPage>;
}
export default App;
