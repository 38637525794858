import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import './ClaimDialog.css';

class ClaimDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      message: "",
      anonymousCheck: false
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.handleClaim = this.handleClaim.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleAnonymousCheckChange = this.handleAnonymousCheckChange.bind(this);
  }

  handleCancel() {
    this.props.handleClose(null, null);
  }

  handleClaim() {
    this.props.handleClose(this.state.name, this.state.message)
  }

  handleNameChange(e) {
    this.setState({
      name: e.target.value
    });
  }

  handleMessageChange(e) {
    this.setState({
      message: e.target.value
    });
  }

  handleAnonymousCheckChange(e) {
    this.setState({
      anonymousCheck: e.target.checked,
      name: (e.target.checked) ? "Anonymous Custard" : ""
    })
  }

  render() {
    if (this.props.isOpen) {
      return (
        <div>
          <Dialog open={this.props.isOpen} onClose={this.handleCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Claim Virtual Pastry</DialogTitle>
            <DialogContent>
              <DialogContentText>
                To make this page more personal you are invited to leave your name:
              </DialogContentText>
              <div className="spacer1"/>
              <Alert severity="info">Please note that anything you enter here will be visible to anyone with access to this URL. 
              In the interest of internet safety you may wish to leave just your first name or a nick name.</Alert>
              <div className="spacer2"/>
              <TextField
                autoFocus
                margin="dense"
                disabled={this.state.anonymousCheck}
                value={this.state.name} 
                id="name"
                label="Name"
                type="text"
                onChange={this.handleNameChange}
                fullWidth
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.anonymousCheck}
                    onChange={this.handleAnonymousCheckChange}
                    name="anonymousCheck"
                    color="secondary"
                  />
                }
                label="Claim anonymously"
              />
              <div className="spacer3"/>
              {this.props.allowMessages ? <TextField
                autoFocus
                margin="dense"
                multiline="true"
                value={this.state.message} 
                id="message"
                label="Message (optional)"
                type="text"
                onChange={this.handleMessageChange}
                fullWidth
              /> : ""}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCancel} color="primary">
                Cancel
              </Button>
              <Button disabled={this.state.name === ""} onClick={this.handleClaim} color="primary">
                Claim
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    } else {
      return <></>;
    }
  }
}

export default ClaimDialog;