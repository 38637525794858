import React from 'react';
import { withRouter } from 'react-router-dom';

import './NavBar.css';
import './Branding.css';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CreateDialogue from './CreateDialog.js';

import { getHost } from './utils.js';

class NavBar extends React.Component {
  constructor(props) {
    super(props);
      // Don't call this.setState() here!
      this.state = {
        createDialogOpen:false
    };
  
    this.routeChange = this.routeChange.bind(this);
    this.startOwnCelebration = this.startOwnCelebration.bind(this);
    this.createDialogCallback = this.createDialogCallback.bind(this);
  }
  createDialogCallback() {
    this.setState({createDialogOpen:false});
    console.log(this.state.createDialogOpen);
  }
  routeChange() {
    let path = `/login`;
    this.props.history.push(path);
    console.log(this.props.history);
  }
   
  startOwnCelebration() {
    this.setState({createDialogOpen:true});
  }

  render() {
    var host = getHost();

    return (
      <>
        <AppBar className="navBar" position="fixed">
          <Toolbar>
            <div>
              <div className="navBarTitle">
                {this.props.title}
              </div>
              <div className="navBarBrand">
                <a href={host}><span className="brand brand-virtual">virtual</span><span className="brand brand-pastries">pastries</span><span className="brand brand-com">.com</span></a>
              </div>
            </div>
            <div style={{ flex: 1 }}></div>
            Share your own virtual pastries here: <div style={{ padding: 10 }}></div><Button variant="contained" color="secondary" onClick={this.startOwnCelebration}>START</Button>
          </Toolbar>
        </AppBar>
        <CreateDialogue  isOpen={this.state.createDialogOpen} handleClose={this.createDialogCallback}></CreateDialogue>
      </>
    );
  }
}

export default withRouter(NavBar);
