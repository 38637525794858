import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import ChatOutlined from '@material-ui/icons/ChatOutlined';
import './MessageDialog.css';

class MessageDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.handleClose()
  }

  render() {
    if (this.props.isOpen) {
      return (
        <>
          <Dialog maxWidth="sm" fullWidth open={this.props.isOpen} onClose={this.handleClose} aria-labelledby="form-dialog-title">
            <DialogContent>
            <Typography className="messageTitle" variant="h4" color="textPrimary" component="p" align="left">
            {this.props.name} says:
            </Typography>
            <div className="sep1"></div>
            <Typography className="messageText" variant="body1" color="textSecondary" component="p" align="left">
              "{this.props.message}"
            </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    } else {
      return <></>;
    }
  }
}

export default MessageDialog;