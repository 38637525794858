import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import './ClaimDialog.css';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import {client} from './feathers';
import Slider from '@material-ui/core/Slider';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { getHost } from './utils.js';


// Connect to the Feathers server using the Socket.io connection
//client.configure(feathers.socketio(socket));
// Set up the Feathers authentication client
//client.configure(feathers.authentication());
const styles = theme => ({
  formControl: {
    minWidth: 200
  },
    textField: {
        width: '90%'
    }
});
class CreationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textFieldValue: "",
      anonymousCheck: false,
      pastries: [],
      OccasionType:"birthday",
      quantities:{},
      steps:this.getSteps(),
      activeStep:0,
      id:"",
      finalname:"",
      allowMessages:false
    };
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleOcasionTypeChange = this.handleOcasionTypeChange.bind(this); 
        this.createCelebration = this.createCelebration.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleCBtoggle = this.handleCBtoggle.bind(this);
  }
  handleCBtoggle() {
    this.setState({allowMessages:!this.state.allowMessages});}
  handleBack() {
      this.setState({activeStep:this.state.activeStep-1})
    }
    handleNext() {
      this.setState({activeStep:this.state.activeStep+1})
    }
   getSteps() {
  return ['Choose Pastries', 'Celebration Details', 'Share URL'];
}
 getStepContent(step) {
  switch (step) {
    case 0:
      return (
      <table style={{width:"100%"}}><colgroup><col style={{width:"40%"}} /><col style={{width:"10%"}} /><col style={{width:"50%"}} /></colgroup><tbody><tr><th style={{textAlign:"left"}}>Pastry</th><th></th><th style={{textAlign:"left"}}>Amount</th></tr>
        {this.state.pastries.map(p => (
        <tr key={p._id}>
        <td>
        {p.name}
        </td><td></td>
        <td>
          <Slider valueLabelDisplay="auto" value={this.state.quantities[p._id]} onChange={(e, v) => this.handleSliderChange(p._id, e, v)}/>
        </td>
      </tr>
        ))}</tbody></table>
        );
    case 1:
      return ( <><Alert severity="info">Please note that anything you enter here will be visible to anyone with access to the URL. </Alert>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Your Name Here"
                type="text"
                fullWidth
                value={this.state.textFieldValue}
                onChange={this.handleNameChange}
              /><br /><FormControl style={{width:"90%"}}><InputLabel id="occasion-type-label">What's the occasion?</InputLabel>
<Select id="occasion-type"
          labelId="occasion-type-label"
          value={this.state.OccasionType}
          onChange={this.handleOcasionTypeChange}
        >
          <MenuItem value={"birthday"}>Birthday</MenuItem>
          <MenuItem value={"new job"}>New job</MenuItem>
          <MenuItem value={"wedding"}>Wedding</MenuItem>
          <MenuItem value={"anniversary"}>Anniversary</MenuItem>
          <MenuItem value={"promotion"}>Promotion</MenuItem>
          <MenuItem value={"project success"}>Project Success</MenuItem>
          <MenuItem value={"project milestone"}>Project Milestone</MenuItem>
</Select></FormControl><FormControlLabel
        control={
          <Checkbox
            checked={this.state.allowMessages}
            onChange={this.handleCBtoggle}
            name="checkedB"
            color="primary"
          />
        }
        label="Allow messages"
      />
          </>);
    case 2:
    if (this.state.id !== "") {
      var host = getHost();
      return (
        <>
          <TextField fullWidth id="standard-read-only-input" label="Your URL" defaultValue={host + "/celebration/" + this.state.id} InputProps={{ readOnly: true, }} />
          <ul className="share-buttons">
            <li><a href={"https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fvirtualpastries.com%2Fcelebration%2F" + this.state.id}><img alt="Share on Facebook" src="images/flat_web_icon_set/color/Facebook.png" /></a></li>
            <li><a href={"https://twitter.com/intent/tweet?source=Celebrate%20" + this.state.finalname + "%20with%20virtualpastries%20at%3A%20http%3A%2F%2Fvirtualpastries.com%2Fcelebration%2F" + this.state.id + "&text=Celebrate%20" + this.state.finalname + "%20with%20virtualpastries%20at%3A%20http%3A%2F%2Fvirtualpastries.com%2Fcelebration%2F" + this.state.id}  title="Tweet" 
            onClick={() => { window.open(`https://twitter.com/intent/tweet?text=` + encodeURIComponent(document.title) + ':%20'  + encodeURIComponent(document.URL)); return false;}}><img alt="Tweet" src="images/flat_web_icon_set/color/Twitter.png" /></a></li>
            <li><a href={"http://pinterest.com/pin/create/button/?url=Celebrate%20" + this.state.finalname + "%20with%20virtualpastries%20at%3A%20http%3A%2F%2Fvirtualpastries.com%2Fcelebration%2F" + this.state.id+"&description="}  title="Pin it"><img alt="Pin it" src="images/flat_web_icon_set/color/Pinterest.png" /></a></li>
          </ul>
        </>);}
    break;
    default:
      return 'Unknown step';
  }
}
  loadPastries() {
    (async () => {

      const pastries = await client.service('pastry').find({
        query: {
          isActive: true,
          $sort: { createdAt: -1 },
          $limit: 25
        }
      });

      this.setState({
        'pastries' : pastries.data,
        'quantities' : Object.fromEntries(pastries.data.map(p => [p._id, 0]))
      });
    })();
  }

  handleOcasionTypeChange(event) {
    //set selection to the value selected
    this.setState({ OccasionType : event.target.value });
  }

  componentWillMount() {
    this.loadPastries();
    client.service('pastries').on('created', celebration => this.loadPastries());
  }
    handleNameChange(e) {
    this.setState({
      textFieldValue: e.target.value
    });
  }
  handleSliderChange(id, e, v) {
    var source = {};
    source[id] = v;
    this.setState({'quantities' : Object.assign(this.state.quantities, source)});
    console.log(Object.values(this.state.quantities));
  }
  createCelebration() {
    this.handleNext();
    // stop us adding excess database entries
    //return false;
    var name = (this.state.textFieldValue[this.state.textFieldValue.length-1].toLowerCase()!=="s")
      ? `${this.state.textFieldValue}'s ${this.state.OccasionType}`
      : `${this.state.textFieldValue}' ${this.state.OccasionType}`;

    var cakes = [];
    for (var cakeid in this.state.quantities) {
      if (this.state.quantities[cakeid] > 0) {
        cakes.push({
          quantity: this.state.quantities[cakeid],
          cakeid: cakeid,
          claims: []
        });
      }
    }

    
    var celebration = {
      name: name,
      cakes: cakes,
      allowMessages:this.state.allowMessages
    };
    console.log(`celebration=${JSON.stringify(celebration)}`);

    (async () => {
      var result = await client.service('celebration').create(celebration);
      console.log("RESULT: " + JSON.stringify(result));
      var id = result._id;
      var name = result.name;
      console.log(id,name);
      this.setState({finalname:name});
      this.setState({id:id});
    })();
  }

render() {
  const { classes } = this.props;
  return (
  <div>
  <Stepper activeStep={this.state.activeStep} orientation="vertical">
  {this.state.steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              {this.getStepContent(index)}
              <div className={classes.actionsContainer}>
                <div>
                  
                </div>
              </div>
            </StepContent>
          </Step>
        ))}</Stepper>
        <Button
                    disabled={this.state.activeStep !== 1}
                    onClick={this.handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.state.activeStep === 1 ? this.createCelebration : this.handleNext}
                    className={classes.button}
                    disabled={!Object.values(this.state.quantities).some(amount => amount>0) || !(this.state.activeStep===0 || this.state.textFieldValue !== "")}
                  >
                    {this.state.activeStep === this.state.steps.length - 1 ? 'Close' : 'Next'}
                  </Button>
        
        </div>);
}
}

export default withStyles(styles)(CreationPage);