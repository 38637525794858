import CreateDialogue from './CreateDialog.js';
import React from 'react';
import Button from '@material-ui/core/Button';
class HomeStartBtn extends React.Component {
  constructor(props) {
    super(props);
      // Don't call this.setState() here!
      this.state = {
        createDialogOpen:false
    };
  
    this.routeChange = this.routeChange.bind(this);
    this.startOwnCelebration = this.startOwnCelebration.bind(this);
    this.createDialogCallback = this.createDialogCallback.bind(this);
  }
  createDialogCallback() {
    this.setState({createDialogOpen:false});
    console.log(this.state.createDialogOpen);
  }
  routeChange() {
    let path = `/login`;
    this.props.history.push(path);
    console.log(this.props.history);
  }
   
  startOwnCelebration() {
    this.setState({createDialogOpen:true});
  }

  render() {
    return (
      <>
        <Button variant="contained" color="secondary" onClick={this.startOwnCelebration}>START</Button>
        <CreateDialogue  isOpen={this.state.createDialogOpen} handleClose={this.createDialogCallback}></CreateDialogue>
      </>
    );
  }
}
export default HomeStartBtn;