import React from 'react';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ClaimDialog from './ClaimDialog';
import ChatOutlined from '@material-ui/icons/ChatOutlined';
import MessageDialog from './MessageDialog';
import TimeAgo from 'react-timeago';

import {client} from './feathers';

import './PastryCard.css';
//minWidth: 220,
//maxWidth: 450
const styles = theme => ({
  root: {
    
  },
  media: {
    height: 120
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  }
});

class PastryCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      claimDialogOpen: false,
      claimDialogCallback: (name) => {},
      messageDialogOpen: false,
      messageDialogCallback: () => {this.setState({messageDialogOpen:false});},
      messageDialogName: "",
      messageDialogMsg: ""
    };
  }

  handleClaimClick() {
    this.setState({claimDialogOpen: true, claimDialogCallback: (name, msg) => { this.claimDialogCallback(name, msg); } });    
  }

  claimDialogCallback(name, message) {
    this.setState({claimDialogOpen: false});
    if (name) {
      // TODO: Use local date/time
      var timestamp = new Date();

      (async () => {
        // TODO: Add error handling.
        await client.service('celebration').patch(this.props.celebration._id,
          { "$push" : { "cakes.$[elem].claims": {  claimer: name, message: message, timestamp: timestamp }}},
          { query: { arrayFilters: [{ "elem.cakeid": this.props.pastry._id }] } }
        )})();
    }
  }

  handleMessageIconClick(claim) {
    this.setState({
      messageDialogOpen:true, 
      messageDialogName:claim.claimer, 
      messageDialogMsg:claim.message
    });
  }

  renderClaim(claim) {
    console.log(claim.timestamp);
    if (claim.message && claim.message !== "") {
      //return <><li key={claim.timestamp}>{claim.claimer}</li></>;
      return (
        <>
          <li key={claim.timestamp}>
            {claim.claimer}
            <div className="spacer1"></div>(<div className="timestamp"><TimeAgo date={claim.timestamp} /></div>, 
              <IconButton className="messageIconButton" size='small' onClick={() => this.handleMessageIconClick(claim)}>
                <ChatOutlined className="messageIcon" color="secondary" fontSize="small"/>
              </IconButton>) 
          </li>
        </>);
    } else {
      return (
        <>
          <li key={claim.timestamp}>
            {claim.claimer}
            <div className="spacer1"></div>(<div className="timestamp"><TimeAgo date={claim.timestamp} /></div>) 
          </li>
        </>);
    }
  }

  render () {
    const { classes } = this.props;

    // Work out which entry in the celebration we need to worry about.
    var celebrationEntry = {};
    this.props.celebration.cakes.forEach(c => {
      if (c.cakeid === this.props.pastry._id) {
        celebrationEntry = c;
      }
    });

    var quantityLeft = celebrationEntry.quantity - celebrationEntry.claims.length;
    
    var quantityText = "";
    var quantityClassName = "";
    if (quantityLeft === 0) {
      quantityText = "No longer available"
      quantityClassName = "quantityOutOfStock"
    } else {
      quantityText = `Available: ${quantityLeft}`
    }

    var claims = "";

    if (celebrationEntry.claims.length > 0) {
      claims = (
        <>
          <Typography variant="body2" color="textSecondary" component="p" align="left">
            Claimed by:
          </Typography>
          <ul>
              {celebrationEntry.claims.reverse().map(c => (
                // TODO: Sort out key.
                this.renderClaim(c)
              ))}
          </ul>
        </>
      );
    }
    var description = this.props.pastry.description;
    return (
      <Card className="grid-item">
        <div>
          <CardMedia
            className={classes.media}
            image={this.props.pastry.url}
            title={this.props.pastry.name}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {this.props.pastry.name}
            </Typography>
            <Typography className={quantityClassName} variant="body1" color="textPrimary" component="p" align="left">
              {quantityText}
            </Typography>   
            {claims}
          </CardContent>
        </div>
        <CardActions>
          <div style={{ flex: 1 }}></div>
          <Button className="cardClaimButton" variant="outlined" color="secondary" disabled={quantityLeft <= 0} onClick={() => this.handleClaimClick()}>
            Claim
          </Button>
        </CardActions>
        <ClaimDialog isOpen={this.state.claimDialogOpen} handleClose={this.state.claimDialogCallback} allowMessages={this.props.celebration.allowMessages}>
        </ClaimDialog>
        <MessageDialog 
          isOpen={this.state.messageDialogOpen} 
          handleClose={this.state.messageDialogCallback} 
          name={this.state.messageDialogName}
          message={this.state.messageDialogMsg}>
        </MessageDialog>
      </Card>
    )
  }
}


export default withStyles(styles)(PastryCard);